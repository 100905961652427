import React, { Fragment, memo, useState } from "react";
import { Col, Divider, Drawer, Menu, Row} from "antd";
import { CkButton, CkIcon } from "../../../CkUI";
import type { MenuProps } from 'antd';

import "./style.css";
import { useHistory} from "react-router-dom";
import { useIsMobileScreen } from "../../../app/Utilities/WindowSize";

const items: MenuProps['items'] = [
  {
    label: 'Beneficios',
    key: 'benefits-section',
  },
  {
    label: 'Herramientas',
    key: 'tools-section',
  },
  //{
  //  label: 'Reconocimiento',
  //  key: 'awareness-section',
  //},
  {
    label: 'Afiliación',
    key: 'prices-section',
  },
  {
    label: 'Contacto',
    key: 'contact-section',
  },
];
interface IProps {
}


const LandingNavigationMenu: React.FC<IProps> = ({ }: IProps) => {
  const isMobile = useIsMobileScreen(967);
  const [current, setCurrent] = useState('');
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const history = useHistory();

  const onClick: MenuProps['onClick'] = e => {
    setCurrent(e.key);

    const element = document.getElementById(e.key);
    const headerOffset = 120; // nav bar offset
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  return (
    <Fragment>
      {
        isMobile ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              boxShadow: "0px 4px 16px 0px #2222221A",
              position: "fixed",
              top: "0",
              width: "100%",
              height: "100px",
              zIndex: "1000",
              backgroundColor: "#FFF",
              padding: "10px 15px 10px 15px",
            }}
          >
            <CkButton
              icon="menu-hamburger"
              type="text"
              onClick={() => {
                setShowLogin(false);
                setOpenMobileDrawer(true);
              }}
            />

            <div onClick={() => {
              window.scrollTo(0, 0);
            }}>
              <CkIcon name="carker-logo-color" width="" height="" fill=""/>
            </div>

            <CkButton
              icon="user"
              type="text"
              onClick={() => {
                setShowLogin(true);
                setOpenMobileDrawer(true);
              }}
            />
            <Drawer
              className="landing-navigation-drawer-mobile"
              onClose={()=> setOpenMobileDrawer(false)}
              closable
              destroyOnClose
              maskClosable
              open={openMobileDrawer}
              contentWrapperStyle={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {
                showLogin ? (
                  <Row justify="center">
                    <Col span={24} style={{ justifyContent: "center", alignItems: "center", display: "flex"}}>
                      <CkButton
                        className="btn-login-landing-nav"
                        type="ghost"
                        style={{ width: "145px", margin: "0 0 10px 0"}}
                        onClick={() => {
                          history.push("/login");
                        }}
                      >
                        Inicia sesión
                      </CkButton>
                    </Col>
                    <Col span={24} style={{ justifyContent: "center", alignItems: "center", display: "flex"}}>
                      <CkButton
                        className="btn-register-landing-nav"
                        style={{ width: "145px"}}
                        onClick={()=> {
                          history.push("/registro");
                        }}
                      >
                        Registra tu taller
                      </CkButton>
                    </Col>
                  </Row>

                ) : (
                // @ts-ignore
                <Menu
                  className="landing-page-menu mobile-lp-menu"
                  onClick={(e)=>{
                    onClick(e);
                    setOpenMobileDrawer(false);
                  }}
                  selectedKeys={[current]}
                  mode="vertical"
                  items={items}
                />
                )
              }


            </Drawer>
          </div>
        ) : (
          <div
            id="start-page"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              boxShadow: "0px 4px 16px 0px #2222221A",
              position: "fixed",
              top: "0",
              width: "100%",
              height: "100px",
              zIndex: "999",
              backgroundColor: "#FFF",
              padding: "10px 15px 10px 15px",
            }}
          >
            <div onClick={() => {
              window.scrollTo(0, 0);
            }}>
              <CkIcon name="carker-logo-color" width="" height="" fill=""/>
            </div>


            <div style={{ display: "flex", flexDirection: "row"}}>
              {/*
              // @ts-ignore*/}
              <Menu
                className="landing-page-menu"
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
              />

              <Divider type="vertical" style={{ height: "44px"}}/>

              <CkButton
                className="btn-login-landing-nav"
                type="ghost"
                style={{ width: "145px", margin: "0 15px 0 20px"}}
                onClick={() => {
                  history.push("/login");
                }}
              >
                <p style={{ fontSize: "14px", fontWeight: "600", margin: 0}}>Inicia sesión</p>

              </CkButton>

              <CkButton
                className="btn-register-landing-nav"
                style={{ width: "145px" }}
                onClick={()=> {
                  history.push("/registro");
                }}
              >
                <p style={{ fontSize: "14px", fontWeight: "600", margin: 0, color: "#FFFFFF" }} >
                  Registra tu taller
                </p>
              </CkButton>
            </div>
          </div>
        )
      }
    </Fragment>

  );
};

export default memo(LandingNavigationMenu);
