import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { TermsAndConditionsContent } from "../TermsAndConditionsPage/TermsAndConditionsContent";

interface IProps {}

export const Terms: FC<IProps> = () => {

  return (
    <>
      <Helmet>‍
        <title>Términos y Condiciones | CarKer Para Talleres</title>‍
        <meta name="description" content="Términos y condiciones de CarKer, la plataforma que conecta a automovilistas con talleres automotrices" data-react-helmet="true" />‍
      </Helmet>

    <div
      id="termsContain"
      className="termsContain"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        //width: windowSize.width < 2292 ? "100%" : "2000px",
        padding: "5%",
      }}
    >
      <TermsAndConditionsContent onDataRetentionClick={()=>{}}/>
    </div>
    </>
  );
};
