import React, {Fragment, memo} from "react";
import { Badge, Col, Row } from "antd";
import { CkIcon } from "../../../CkUI";
import { useIsMobileScreen } from "../../../app/Utilities/WindowSize";
import "./style.css"

interface IProps {}

const steps = [
  "Proporcionar la información básica requerida sobre tu taller.",
  "Enviar los comprobantes requeridos para la verificación de tu taller y del dueño o gerente.",
  "CarKer revisará tu registro en línea y se pondrá en contacto contigo. Te asistirán para completar los requisitos de ser necesario.",
  "Cuando el alta de tu taller esté completa, se habilitará el uso en la plataforma y ¡oficialmente serás parte de CarKer!"
]

const AffiliationSection: React.FC<IProps> = ({ }: IProps) => {
  const isMobile = useIsMobileScreen(991);
  return (
    <div className="affiliation-main-container">

      <Row>
        <p id="prices-section" className="affiliation-section-main-title" style={{ }}>
          Pasos para afiliar tu taller
        </p>
      </Row>

      {
        !isMobile && (
          <Fragment>
            <Row justify="center">
              <CkIcon name="landing-arrow-h-top"/>
              <div style={{width: "125px"}}/>
            </Row>
            <Row justify="center">
              {
                steps.map((step, index) => {
                  return (
                    <Col lg={6} sm={20} key={`aff-item-${index}`}>
                      <Badge count={index + 1} className="affiliation-step-badge"/>
                      <div
                        style={{
                          padding: "15px",
                          backgroundColor: "#EFF6FF",
                          borderRadius: "32px",
                          height: "127px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px"
                        }}
                      >
                        <p style={{fontSize: "16px", textAlign: "center"}}>{step}</p>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
            <Row>
              <Col span={12} style={{ display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <CkIcon name="landing-arrow-h-bottom" />
                <div style={{ width: "125px"}}/>
              </Col>
              <Col span={12} style={{ display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <CkIcon name="landing-arrow-h-bottom"/>
                <div style={{width: "125px"}}/>
              </Col>
            </Row>
          </Fragment>
        )
      }
      {
        isMobile && (
          <Row justify="center">
            <Col sm={3} xs={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  justifyItems: "center",
                  height: "100%"
                }}
              >
                <CkIcon name="landing-arrow-v-left" />
              </div>
            </Col>
            <Col sm={18} xs={18}>
              <Row>
                {
                  steps.map((step, index) => {
                    return (
                      <Col sm={24} key={`aff-item-${index}`}>
                        <Badge count={index + 1} className="affiliation-step-badge"/>
                        <div
                          style={{
                            padding: "15px",
                            backgroundColor: "#EFF6FF",
                            borderRadius: "32px",
                            height: "127px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px"
                          }}
                        >
                          <p style={{fontSize: "16px", textAlign: "center"}}>{step}</p>
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>
            <Col sm={3} xs={3}>
              <div style={{ display: "flex", flexDirection: "column", height: "50%", alignItems: "flex-start", justifyContent: "center"}}>
                <CkIcon name="landing-arrow-v-right" />
              </div>
              <div style={{ display: "flex", flexDirection: "column", height: "50%", alignItems: "flex-start", justifyContent: "center"}}>
                <CkIcon name="landing-arrow-v-right" />
              </div>
            </Col>
          </Row>
        )
      }
    </div>
  );
};

export default memo(AffiliationSection);
