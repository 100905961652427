import React, { memo } from "react";
import { Col, Row } from "antd";
import { CkIcon } from "../../../CkUI";
import "./style.css";

interface IProps {
}


const WorkshopToolsSection: React.FC<IProps> = ({ }: IProps) => {

  return (
    <div className="ws-tools-main-container">
      <p id="tools-section" className="tool-section-main-title">
        Herramientas para acelerar tu productividad
      </p>

      <p className="tool-section-main-subtitle">
        CarKer brinda herramientas para transformar y optimizar la gestión de tu taller.
      </p>

      <Row style={{ backgroundColor: "#2369BD", minHeight: "407px", borderRadius: "10px", marginBottom: "10px"}}>
        <Col lg={12} md={24}>
          <div className="tool-text-container">
            <p className="tool-section-title white-text">
              Obtén un vistazo de cómo va tu taller
            </p>
            <p className="tool-section-text gray-text">
              Monitorea tus ventas por períodos de tiempo y descubre cuáles servicios son los más exitosos mediante un
              tablero personalizado para tu taller.
            </p>
          </div>

        </Col>

        <Col lg={12} md={24}>
          <div className="screenshot-container">
            <CkIcon name="landing-screenshot-1" width="" height="" fill=""/>
          </div>
        </Col>
      </Row>

      <Row className="reverse-order-container" style={{ backgroundColor: "#EFF6FF", minHeight: "407px", borderRadius: "10px", marginBottom: "10px"}}>
        <Col lg={12} md={24}>
          <div className="screenshot-container">
            <CkIcon name="landing-screenshot-2" width="" height="" fill=""/>
          </div>
        </Col>
        <Col lg={12} md={24}>
          <div className="tool-text-container">
            <p className="tool-section-title">
              Organiza y rastrea el progreso de tus citas en un mismo lugar
            </p>
            <p className="tool-section-text">
              El calendario permite una visualización y seguimiento detallado de tus citas, con la opción de enviar actualizaciones rápidamente a tus clientes.
            </p>
          </div>
        </Col>
      </Row>

      <Row style={{ backgroundColor: "#2369BD", minHeight: "407px", borderRadius: "10px", marginBottom: "10px"}}>
        <Col lg={12} md={24}>
          <div className="tool-text-container">
            <p className="tool-section-title white-text">
              Gestiona y conoce mejor a tus clientes
            </p>
            <p className="tool-section-text gray-text">
              La lista de clientes cuenta con un historial de citas y una ficha con los datos más importantes para la gestión de tus clientes.
            </p>
          </div>

        </Col>

        <Col lg={12} md={24}>
          <div className="screenshot-container">
            <CkIcon name="landing-screenshot-3" width="" height="" fill=""/>
          </div>
        </Col>
      </Row>

      <Row className="reverse-order-container" style={{ backgroundColor: "#EFF6FF", minHeight: "407px", borderRadius: "10px", marginBottom: "10px"}}>
        <Col lg={12} md={24}>
          <div className="screenshot-container">
            <CkIcon name="landing-screenshot-4" width="" height="" fill=""/>
          </div>
        </Col>

        <Col lg={12} md={24}>
          <div className="tool-text-container">
            <p className="tool-section-title">
              Descubre la percepción de tus clientes para convertirte en su taller ideal
            </p>
            <p className="tool-section-text">
              Visualiza las reseñas y calificaciones de tus clientes y obtén una calificación general de tu taller para conocer la impresión tus clientes.
            </p>
          </div>

        </Col>

      </Row>

    </div>
  );
};

export default memo(WorkshopToolsSection);
