import React, { memo } from "react";
import { Col, Row } from "antd";
import { CkIcon } from "../../../CkUI";

import "./style.css";

interface IProps {}
const commitments = [
  {
    icon: "hand-shake",
    title: "Transparencia",
    commitmentList: [
      "Honestidad y claridad en los diagnósticos y recomendaciones de servicio.",
      "Obtener consentimiento del usuario antes de cualquier trabajo."
    ]
  },
  {
    icon: "gear",
    title: "Calidad",
    commitmentList: [
      "Utilizar sólo piezas y refacciones de comprobada calidad y de acuerdo con las especificaciones del vehículo.",
      "Respetar la garantía durante un periodo o kilometraje específico, incluido para partes eléctricas y/o electrónicas."
    ]
  },
  {
    icon: "hand-heart",
    title: "Respeto a compromisos",
    commitmentList: [
      "Cumplir los acuerdos con el cliente, como las citas, cotizaciones, horarios de entrega, etc."
    ]
  },
  {
    icon: "hand-ok",
    title: "Precio justo",
    commitmentList: [
      "Precios claros por mano de obra, refacciones y otros servicios.",
      "Respetar las cotizaciones aprobadas vía CarKer por el usuario."
    ]
  },
  {
    icon: "hands",
    title: "Inclusión",
    commitmentList: [
      "Aceptar y respetar a todos los clientes sin diferencias por su género, edad, sexo, preferencias, condición social, religión o cualquier otra razón. ",
    ]
  },
]

const CarkerWorkshopBanner: React.FC<IProps> = ({ }: IProps) => {

  return (
    <div style={{ padding: "20px 20px 0 20px", backgroundColor: "#001450" }}>
      <Row>
        <Col span={4}>
          <CkIcon className="landing-page-banner-icon" name="landing-banner-icon-1" width="" height="" fill="" />
        </Col>
        <Col span={16}>
          <div>
            <p className="banner-main-title">
              ¿Qué significa ser un taller afiliado a CarKer?
            </p>
            <p className="banner-main-subtitle">
              Todos los talleres afiliados dentro de la plataforma han{" "}
              <b>aprobado un estricto proceso de</b> <b>validación</b> de CarKer y{" "}
              <b>aseguran su ética comercial por escrito.</b>
            </p>
          </div>
          <Row style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "15px"}}>
            {
              commitments.map((item) => {
                return (
                  <Col md={4} sm={12} className="banner-commitment-icon-container">
                    <CkIcon className="banner-commitment-icon" name={item.icon} />
                    <p
                      style={{
                        color: "#FFF",
                        fontSize: "14px",
                        textAlign: "center",
                        fontWeight: 600,
                        paddingTop: "10px"
                      }}
                    >
                      {item.title}
                    </p>
                  </Col>
                )
              })
            }
          </Row>
        </Col>
        <Col span={4} className="bottom-icon-container">
          <CkIcon className="landing-page-banner-icon " name="landing-banner-icon-2" width="" height="" fill="" />
        </Col>
      </Row>
    </div>
  );
};

export default memo(CarkerWorkshopBanner);
