import React, {Fragment, memo} from "react";
import { Col, Row } from "antd";
import { CkButton, CkIcon } from "../../../CkUI";

import "./style.css";
import { useWindowSize } from "../../../app/Utilities/WindowSize";
interface IProps {}

const ContactSection: React.FC<IProps> = ({ }: IProps) => {
  const windowSize = useWindowSize();
  return (
    <div className="contact-section-main-container">

      <Row justify="center">
        <Col span={24} style={{ display: "flex",justifyContent: "center"}}>
          <CkIcon name="customer-service" height="" fill="" width=""/>
        </Col>

        <Col span={24}>
          <p id="contact-section" className="contact-section-main-title">
            ¿Tienes dudas o quisieras obtener más información?
          </p>
        </Col>

        <Col span={24}>
          <p className="contact-section-main-subtitle">
            Ponte en contacto con nosotros en cualquiera de nuestros canales. Tu opinión es muy valiosa para nosotros.
          </p>
        </Col>
      </Row>

      <Row className="contact-options-container">
        <Col lg={8} md={24} className="contact-option-item">
          <CkButton
            icon="circled-whatsapp"
            type="text"
            onClick={()=> {
              window.open("https://wa.me/+525564359958", '_blank').focus();
            }}
          >
            <p style={{ margin: 0, fontSize: "16px", fontWeight: 600  }}>Envíanos un WhatsApp</p>
          </CkButton>
        </Col>

        <Col lg={8} md={24} className="contact-option-item">
          <CkButton
            icon="circled-phone"
            type="text"
            onClick={()=>{
              window.open("tel:5522820800", '_blank').focus();
            }}
          >
            <p style={{ margin: 0, fontSize: "16px", fontWeight: 600 }}>Llámanos al 55 2282 0800</p>
          </CkButton>
        </Col>

        <Col lg={8} md={24}  className="contact-option-item">
          <CkButton
            icon="circled-envelop"
            type="text"
            onClick={()=>{
              window.open("mailTo:soporte@carker.com.mx", '_blank').focus();
            }}
          >
            <p style={{ margin: 0, fontSize: "16px", fontWeight: 600  }}>Escríbenos a soporte@carker.com.mx</p>
          </CkButton>
        </Col>
      </Row>

      <Row className="contact-section-schedule-container">
        {
          windowSize.width < 967 ? (
            <Fragment>
              <p className="contact-section-schedule-text">
                Nuestro horario de atención es:
              </p>
              <p className="contact-section-schedule-text">
                <span className="bold">Lunes a viernes: </span> 8:00 a 18:00 hrs.
              </p>
              <p className="contact-section-schedule-text">
                <span className="bold">Sábados: </span>9:00 a 14:00 hrs.
              </p>
            </Fragment>
          ) : (
            <p className="contact-section-schedule-text">
              Nuestro horario de atención es:
              <span className="bold"> Lunes a viernes: </span> 8:00 a 18:00 hrs.
              <span className="bold" style={{marginLeft: "10px"}}>Sábados: </span>9:00 a 14:00 hrs.
            </p>
          )
        }

      </Row>

    </div>
  );
};

export default memo(ContactSection);
