import React, { memo } from "react";
import { Col, Row } from "antd";
import { CkIcon } from "../../../CkUI";
import "./style.css";

interface IProps {}

const BenefitsSection: React.FC<IProps> = ({ }: IProps) => {

  return (
    <div id="benefits-section" className="benefits-main-container" style={{ padding: "20px" }}>
      <p  className="benefit-section-main-title">
        Beneficios
      </p>

      <Row justify="space-evenly">
        <Col lg={8} md={12} sm={10}>
          <div className="benefit-item-container">
            <CkIcon className="benefit-item-icon" name="landing-time-management" width="" height="" fill="" />
            <p className="benefit-item-text">
              Optimiza la gestión de tu taller para ahorrar tiempo
            </p>
          </div>

        </Col>
        <Col lg={8} md={12} sm={10}>
          <div className="benefit-item-container" >
            <CkIcon className="benefit-item-icon" name="landing-billing" width="" height="" fill=""/>
            <p className="benefit-item-text">
              Incrementa el potencial de tu facturación
            </p>
          </div>

        </Col>
        <Col lg={8} md={12} sm={10}>
          <div className="benefit-item-container" >
            <CkIcon className="benefit-item-icon" name="landing-postsell" width="" height="" fill=""/>
            <p className="benefit-item-text">
              Fortalece tu presencia post-venta
            </p>
          </div>

        </Col>
        <Col lg={8} md={12} sm={10}>
          <div className="benefit-item-container" >
            <CkIcon className="benefit-item-icon" name="landing-client-history" width="" height="" fill=""/>
            <p className="benefit-item-text">
              Registra y lleva un historial detallado de tus clientes
            </p>
          </div>

        </Col>
        <Col lg={8} md={12} sm={10}>
          <div className="benefit-item-container" >
            <CkIcon className="benefit-item-icon" name="landing-efficiency" width="" height="" fill=""/>
            <p className="benefit-item-text">
              Aumenta la eficiencia operativa de tu taller
            </p>
          </div>

        </Col>
        <Col lg={8} md={12} sm={10}>
          <div className="benefit-item-container" >
            <CkIcon className="benefit-item-icon" name="landing-reputation" width="" height="" fill=""/>
            <p className="benefit-item-text">
              Mejora  la reputación y percepción de tu taller
            </p>
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default memo(BenefitsSection);
