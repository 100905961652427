import React from "react";
import { Helmet } from "react-helmet";
import { useWindowSize } from "../../app/Utilities/WindowSize";

interface IProps {}

export const Disclaimer: React.FC<IProps> = () => {

  const windowSize = useWindowSize();

  return (
    <>
      <Helmet>‍
        <title>Aviso Legal | CarKer Para Talleres</title>‍
        <meta name="description" content="" data-react-helmet="true" />‍
      </Helmet>

    <div
      id="disclaimer"
      className="termsContain"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          padding: "5%",
          paddingTop: 100,
          backgroundColor: "white",
          width: windowSize.width < 2292 ? "100%" : "2000px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <p
              style={{
                color: "#2f66f2",
                fontSize: windowSize.width <= 992 ? "22px" : "24px",
                fontFamily: "Emprint-semibold",
                fontWeight: 900,
              }}
            >
              Aviso Legal
            </p>
            <p
              style={{
                color: "black",
                fontSize: windowSize.width <= 992 ? "13px" : "15px",
                fontWeight: 200,
                fontFamily: "Emprint-regular",
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  fontFamily: "Emprint-semibold",
                  fontSize: windowSize.width <= 992 ? "13px" : "15px",
                  color: "#454545",
                  marginBottom: "0px",
                }}
              >
                Recomendación de producto:
              </p>
              <br></br>
              La información que se muestra en este sitio web está sujeta a cambios sin notificaciones, entre otras consideraciones debido a la investigación continua, actualizaciones tecnológicas y obligaciones regulatorias. Aunque se ha hecho todo lo posible para que la información sea exacta, ExxonMobil México, S.A. de C.V. (“ExxonMobil”) y/o sus filiales no serán responsables de las pérdidas, daños y perjuicios o daños de cualquier tipo causados por errores, omisiones, imprecisiones o interpretaciones erróneas de los contenidos que se muestran en esta página web.
              <br></br>
              La información mostrada es propiedad de ExxonMobil, o bien, este cuenta con las autorizaciones para su uso, por lo que la misma no podrá ser copiada, reproducida o divulgada de ninguna manera por el usuario sin el consentimiento previo por escrito de ExxonMobil.
              <br></br>
              La información y las recomendaciones que se ofrecen a los usuarios, está sujeta a la decisión final del usuario y/o cliente. De igual forma, la información y las recomendaciones se realizan sobre la base de los productos ExxonMobil, cuando no están en combinación con ningún otro producto o material. La información y las recomendaciones están sujetas a cambio y no se anteponen ni sustituyen a ninguna recomendación emitida por los fabricantes de motor/equipo/maquinaria. ExxonMobil y/o sus filiales no podrán ser sujetos de responsabilidad directa, indirecta o daños consecuenciales por cualquier pérdida, daños y perjuicios, o lesión sufrida o incurrida, directa o indirectamente, en cuanto a cualquier resultado obtenido o que surja de cualquier uso del producto de esta información técnica y recomendaciones para dicho uso.
              <br></br>
              Al consultar la información y recomendaciones de la página web, el usuario se sujeta a las leyes aplicables de los Estados Unidos Mexicanos y a la jurisdicción de los tribunales competentes en la Ciudad México, renunciando expresamente a la competencia de los tribunales que les corresponderían en razón de su domicilio o cualquier otra.
              <br></br>
              <br></br>

              <p
                style={{
                  fontFamily: "Emprint-semibold",
                  fontSize: windowSize.width <= 992 ? "13px" : "15px",
                  color: "#454545",
                  marginBottom: "0px",
                }}
              >
                Precios – cotización de servicios:
              </p>
              <br></br>
              Cualquier información o comunicación referente a precios y contenida en la presente plataforma es responsabilidad única del Taller que emite la cotización/información que corresponda. El Usuario es el único responsable de la aceptación de una cotización. La información contenida en la cotización que corresponda se considera inicial y la misma puede variar respecto de la cotización final que el Taller emita derivada de los productos y servicios efectivamente realizados al vehículo del Usuario. El Usuario entiende y acepta que CarKer es únicamente una plataforma de conexión entre Talleres y Usuarios de CarKer y que CarKer no incide directa o indirectamente ni es responsable de ninguno de los productos, servicios y precios ofertados por los Talleres en la plataforma. CarKer se deslinda de cualquier y toda responsabilidad respecto a lo anterior todo lo cual es extensivo a cualquier compañía que directa o indirectamente desarrolla CarKer. Para mayor información consulta nuestros Términos y Condiciones.
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
