import React, { memo } from "react";
import { Col, Row } from "antd";
import { CkButton } from "../../../CkUI";
import { useHistory } from "react-router-dom";
import ClickableVideoPlayer from "./ClickableVideoPlayer";
import "./style.css";

interface IProps {}

const domain = process.env.REACT_APP_BLOB_ACCOUNT;
const containerName = process.env.REACT_APP_IMAGES_CONTAINER;

const VideoSection: React.FC<IProps> = ({ }: IProps) => {
  const history = useHistory();
  const videoSrc= `${domain}/${containerName}/workshop-landing/bienvenida-talleres.mov`;

  return (
    <div className="video-section-main-container">
      <Row className="reverse-mobile-container">
        <Col lg={10} md={24} className="video-section-text-container">
          <p className="video-section-main-title">
            Lleva la gestión de tu taller a la máxima potencia
          </p>

          <p className="video-section-main-subtitle">
            CarKer impulsa a tu taller o centro de servicio hacia una <span className="bold">profesionalización digital</span>, ayudando a administrar la relación con tus clientes y a controlar la operación en tiempo real.
          </p>

          <CkButton
            onClick={
              () => {
                history.push("/registro")
              }
            }
          >
            Registra tu taller
          </CkButton>
        </Col>
        <Col lg={14} md={24}>
          <ClickableVideoPlayer videoSrc={videoSrc} />
        </Col>
      </Row>
    </div>
  );
};

export default memo(VideoSection);
