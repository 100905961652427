import React from "react";
import { Divider, Layout } from "antd";
//import SubscriptionSection from "./SubscriptionSection";
import AffiliationSection from "./AffiliationSection";
//import FeaturedWorkshopsSection from "./FeaturedWorkshopsSection";
import CarkerWorkshopBanner from "./CarkerWorkshopBanner";
import WorkshopToolsSection from "./WorkshopToolsSection";
import BenefitsSection from "./BenefitsSection";
import VideoSection from "./VideoSection";
import LandingNavigationMenu from "./LandingNavigationMenu";
import LandingFooter from "../../app/Components/LandingFooter";
import ContactSection from "./ContactSection";
import SasTokenRefresher from "../../app/Components/SasTokenRefresher";

export const LandingPage: React.FC = () => {

  return (
    <Layout>
      <SasTokenRefresher />
      <LandingNavigationMenu />

      <div style={{ marginTop: "100px", backgroundColor: "#FFF"}} >
        <VideoSection />

        <BenefitsSection />

        <WorkshopToolsSection />

        <CarkerWorkshopBanner />

        {/* <FeaturedWorkshopsSection />*/}

        <AffiliationSection />

        {/*<SubscriptionSection />*/}

        <Divider style={{ margin: "25px 5% 25px 5%", width: "90%", minWidth: "90%"}} />

        <ContactSection />
      </div>

      <LandingFooter />
    </Layout>
  );
};
