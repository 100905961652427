import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { getSasToken } from "../../../../stores/selectors";
import CkIcon from "../../../../CkUI/components/atoms/CkIcon";
import "./style.css";

interface IProps {
  videoSrc: string;
}

const ClickableVideoPlayer: React.FC<IProps> = ({ videoSrc }: IProps) => {
  const sasToken: string = useSelector(getSasToken);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="clickable-video-main-container">
      {
        showVideo && sasToken && (
          <video width="100%" autoPlay onClick={() => setShowVideo(false)}>
            <source src={`${videoSrc}?${sasToken}`}/>
            Sorry, your browser doesn't support embedded videos.
          </video>
        )
      }
      {
        !showVideo && (
          <div
            onClick={()=>{ if(sasToken) setShowVideo(true)}}
          >
            <CkIcon className="screenshot-main-video" name="landing-video-link" width="" height="" fill=""/>
          </div>
        )
      }
    </div>
  );
};

export default memo(ClickableVideoPlayer);
